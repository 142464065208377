import { ProfileStatus } from "@/types";

export enum EUserEmail {
  email = "email",
}

export enum EUserPassword {
  password = "password",
}

export enum EUserGender {
  male = "MALE",
  female = "FEMALE",
}

export enum EUserDetails {
  isChildAccount = "isChildAccount",
  caregiverFirstName = "caregiverFirstName",
  caregiverLastName = "caregiverLastName",
  firstName = "firstName",
  lastName = "lastName",
  gender = "gender",
  birthday = "birthday",
  phone = "phone",
  smsConsent = "smsConsent",
}

// Carepoint requires a 2nd address line (address2),
// but not all address forms are for services handled by Carepoint
export enum EUserAddress {
  address = "address",
  address2 = "address2",
  city = "city",
  state = "state",
  zip = "zip",
}

// there's no solid way to merge enums
// but we can spread them into a const
export const EUserSignup = {
  ...EUserEmail,
  ...EUserPassword,
  ...EUserDetails,
};

// user address comes from Encounter
export interface IUserAddress {
  address?: string;
  address2?: string;
  city?: string; // currently missing from EHR object?
  state?: string;
  zip?: string;
}

export interface IUserDetails extends IUserAddress {
  firstName: string;
  lastName: string;
  gender: string;
  dateOfBirth: string; // TODO: update to birthday
  phoneNumber: string;
  // for FormRegisterKitSettings
  phone?: string;
  birthday?: string;
}

export interface IUser extends IUserAddress {
  isChildAccount: boolean;
  caregiverFirstName?: string;
  caregiverLastName?: string;
  id: number;
  email: string;
  firstName: string;
  lastName: string;
  phoneNumber: string;
  dateOfBirth: string;
  gender: string;
  personalGenderPronoun?: string;

  smsConsent: boolean;

  // ID
  isEmailVerified: boolean;
  // later with migration
  // hasID?: boolean; // always required
  // hasInsuranceID?: boolean;

  // EHR quiz
  questionnaireAnswers?: Record<string, unknown>;

  // Status
  profileStatus?: ProfileStatus;

  // Charm
  createdAt: string;
  charmEncounterId?: string;
  charmPatientId?: string;
  charmPatientRecordId?: string;
}
