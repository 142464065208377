// Profile Registration Status
// Due to legacy product iteration, this mostly handles
// Registration Recommendation progress status
export enum ProfileStatus {
  // No encounter: has not finished quiz
  quizNotCompleted = "QUESTIONNAIRE",
  // No encounter: completed quiz
  // Previously, this meant user was at Pharmacy details
  // This will now mean user is at Recos
  requestedRecoNotCompleted = "REQUEST_RECOMMENDATION",
  // NOT USED IN THE UI, SERVER ONLY
  // Even though EncounterStatus.approved is our main method for deternming, we still need this for server prurposes
  awaitingEncounterApproval = "AWAITING_APPROVAL",
}

// Genesis Encounter Status (from a Registration)
// In future, we may need an object for multiple historical Encounters
// but for now, given that we are storing each Encounter to a user's database
// record and we only serve the genesis Encounter from a Registration,
// we're focussed on the current Encounter only.
export enum EncounterStatus {
  none = "NONE",
  // An encounter will have status awaiting until it has been processed by the preselected-approval cron job,
  // even if it's been signed
  // The UI expects approved encounter means payment has been processed
  awaiting = "AWAITING_APPROVAL",
  approved = "APPROVED",
}
