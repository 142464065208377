import { IUser } from "@/types";

/* For more details on the v1 Todo Messages, see: https://www.notion.so/callum/3fb0ab2dbdca41beaebbdb4a7f19c01e?v=5b5317ac7bf7435282cdf14c6f5b4691 */

export type TTodoStage = "quiz" | "customer" | "case" | "checkout";

export enum ETodoStage {
  quiz = "quiz",
  customer = "customer",
  checkout = "checkout",
  case = "case",
}

export type TTodoStatus =
  | "incomplete"
  | "awaiting"
  | "problem"
  | "available"
  | "success"
  | "new";

export enum ETodoStatus {
  incomplete = "incomplete",
  awaiting = "awaiting",
  problem = "problem",
  available = "available",
  success = "success",
  new = "new",
}

export type TQuizDiagnosticPartner = "cleared" | "allergenis" | "test";

export enum EQuizDiagnosticPartner {
  cleared = "cleared",
  allergenis = "allergenis",
  test = "test",
}

/* A Todo ID comes from the server. It must consist of a stage + status + diagnosticPartner (optional). On the fronetend, a Todo receives an ID only. Stage + status + partner are passed within the ID's string */
export type TTodoId =
  | "quiz-incomplete"
  | "checkout-incomplete"
  | "case-problem"
  | "case-problem-missing-id"
  | "case-problem-missing-insurance-id"
  | "case-available"
  | "case-available-followup"
  | "upload-photo-id"
  | "upload-health-insurance-id"
  | "upload-photo-id-and-health-insurance-id";

export enum ETodoId {
  quizIncomplete = "quiz-incomplete",
  checkoutIncomplete = "checkout-incomplete",
  caseProblem = "case-problem",
  caseProblemId = "case-problem-missing-id",
  caseProblemInsId = "case-problem-missing-insurance-id",
  caseAvailable = "case-available",
  caseAvailableFollowup = "case-available-followup",
  uploadPhotoId = "upload-photo-id",
  uploadHealthInsuranceId = "upload-health-insurance-id",
  uploadPhotoIdAndHealthInsuranceId = "upload-photo-id-and-health-insurance-id",
}

export interface ITodo {
  date?: string;
  id: TTodoId;
  // NB! diagnosticPartner is currently not available for the `case` stage
  diagnosticPartner: TQuizDiagnosticPartner;
  link?: string;
}

// On any slug page, set partner type to match the expected query type, not TQuizDiagnosticPartner. This is b/c we access it on the slug via query params, which must use string | string[]
export type TStringFromRouterQuery = string | string[];

export interface ITodoMessage {
  user: IUser;
  id: TTodoId;
  partner?: TStringFromRouterQuery;
  link?: string;
}
