import React from "react";

// Util: boolean toggle for styling FormControls
const { useState } = React;
export default function useActiveOnFocus(initialState = false) {
  const [active, setActive] = useState(initialState);
  const eventHandlers = {
    onFocus: () => setActive(true),
    onBlur: () => setActive(false),
  };
  return [active, eventHandlers];
}
