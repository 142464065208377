import {
  ProductIntervals,
  ProductTypes,
  DatoProduct,
  IUserAddress,
  ICheckoutCouponDetails,
} from "@/types";

export enum CheckoutVariant {
  RECOMMENDATION = "recommendation",
  TREATMENT = "treatment",
  SHOP = "shop",
}

export enum EQuizVariant {
  ENVIRONMENTAL = "environmental",
  ALLERGENIS = "allergenis",
}

// --------------------------------------
// Passed from server for Stripe purposes
// --------------------------------------

export interface CheckoutBaseTypes {
  // EVENTS
  // server loading state
  isDisabled?: boolean;
  // USER
  // did the user choose Carepoint Pharmacy?
  // if not, they chose a custom "other" Pharmacy
  accountUsingCarepoint?: boolean;
  // LAYOUT
  // We use 3 x CheckoutVariant
  variant?: CheckoutVariant;
  // If CheckoutVariant.RECOMMENDATION, we set a quizVariant
  quizVariant?: EQuizVariant;
  // show the page header
  showHeader?: boolean;
}

export interface CheckoutFeeTypes {
  shippingFee: number;
  doctorReviewFee?: number;
}

// -----------------------------
// computed in setCheckoutPrices
// -----------------------------

export class CheckoutProductTypes {
  mergedSelectedProducts?: ProductTypes[];
  mergedRecommendedProducts?: ProductTypes[];
  nonInsuranceProducts?: ProductTypes[];
  singleProducts?: ProductTypes[];
  recurringProducts?: ProductTypes[];
  recurringRxProducts?: ProductTypes[];
  recurringNonRxProducts?: ProductTypes[];
  recurringCarepointFulfilledProducts?: ProductTypes[];
}

export class CheckoutPurchaseTypes {
  hasPurchase?: boolean;
  hasSingleProduct?: boolean;
  hasRecurringProduct?: boolean;
  hasRecurringNonInsPurchase?: boolean;
  hasRecurringRxPurchase?: boolean;
  hasRecurringNonRxPurchase?: boolean;
  hasShippingFee?: boolean;
  // show the interval selector
  showSubscriptionIntervalPanel?: boolean;
}

export class CheckoutTotalTypes {
  totalToday?: number;
  totalRecurring?: number;
  totalRecurringProducts?: number;
  totalRecurringNonRxProducts?: number;
  totalThirtyDayProducts?: number;
  totalNinetyDayProducts?: number;
  totalEveryInterval?: number;
  totalEveryIntervalPlusToday?: number;
  totalSingleProducts?: number;
  totalDueIfPrescribed?: number;
}

export class CheckoutDiscountTypes {
  // directly set via ProductIntervals.ninetyDay
  isNinetyDaySupply?: boolean;
  // computed in setCheckoutPrices
  ninetyDayDiscount?: number;
  ninetyDayDiscountAnnum?: number;
}

export class CheckoutTypes {}
export interface CheckoutTypes extends CheckoutBaseTypes, CheckoutFeeTypes {
  // cartId
  cartId?: string;
  // INTERVAL
  interval?: ProductIntervals;
  // PRODUCTS
  datoProducts?: DatoProduct[];
  selectedProducts: ProductTypes[];
  recommendedProducts?: ProductTypes[];
  recurringShippingFee?: number;
  // TOTALS: server computed for Stripe purposes
  totalDueToday: number;
  totalDueIfPrescribed?: number;
  totalRecurringProductsCost?: number;
  // TOTALS COUPON
  couponDetails?: ICheckoutCouponDetails;
  // USER
  address?: IUserAddress;
  stateId?: string;
  // EVENTS
  onIntervalChange?: (string: string) => Promise<void>;
  onItemsChange?: (Array: any) => Promise<void>;
  onSubmit: (string) => Promise<Record<string, any>>;
  addToAbandonedCartList?: () => void;
}

// -----------------------------
// from the server
// -----------------------------

export interface ICheckoutCouponFromServer {
  code: string;
  amountOff: number;
  percentOff: number;
  appliesTo: string[];
}
export interface ICheckoutTotals {
  couponSavings: number;
  coupon: ICheckoutCouponFromServer;
  shippingFee: number;
  recurringShippingFee: number;
  doctorReviewFee: number;
  totalDueToday: number;
  totalDueIfPrescribed: number;
  totalRecurringProductsCost: number;
}
