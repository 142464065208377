// import { IWizardStep } from "@/types";
// import { ResponsiveImage } from "@/types";

// NOTE WELL!
// QM namespace = Quiz Machine
// CH namespace = Capable Health

export enum EQMMedicationInput {
  Yes = "Yes",
  No = "No thanks",
  Unsure = "Not sure",
}

export enum EQMRadioInput {
  Yes = "Yes",
  No = "No",
}

// see: https://www.notion.so/callum/CF-Form-Patterns-58a2d3dc1de04721a754edd730f41e02
export enum ECHQuestionType {
  // we only handle these for now
  multichoice = "MultipleChoiceQuestion",
  singlechoice = "SingleChoiceQuestion",
  comment = "CommentQuestion",
  information = "InformationQuestion",
  hidden = "HiddenQuestion",
  email = "EmailQuestion",
  number = "NumberQuestion",
  phone = "PhoneNumberQuestion",
  // we may handle these later
  scale = "ScaleQuestion",
  date = "DateQuestion",
  upload = "UploadQuestion",
  // this was previously available, now deprecated by CH
  text = "TextQuestion",
}

// Capable Health answer shape
export interface ICHQuestionAnswer {
  id: string;
  title: string;
  position: number;
  popover?: string;
}
// Capable Health question shape
export interface ICHQuestion {
  id: string;
  type: string; // will be one of the ECHQuestionType
  position: number;
  title: string;
  required?: boolean;
  metadata: {
    attributes?: {
      subheading?: string;
      description?: string;
      descriptionLink?: string;
      inputLabel?: string;
      isSingleLineInput?: boolean;
    };
    page?: number;
    conditionalRules?: Array<IConditionalRule>;
  };
  answers?: ICHQuestionAnswer[] | [];
}

// Step shape
// NB! The `name` prop is what Formik works from. `name` has already been extracted from `question` within `FormQMStep` so that we have a single source of truth.
export interface IQMQuestionInput {
  name: string;
  question?: ICHQuestion;
  checkboxes?: Array<{ id: string; title: string; popover?: string }>;
  // options?: string[];
}

// Capable Health survey shape
export interface ICHSurvey {
  id: string;
  title: string;
  url: string;
  questions: ICHQuestion[];
}

export interface ICHSubmission {
  submission: ICHSubmissionObject;
}

// full or partial survey submission
export interface ICHSubmissionObject {
  // provide an id when updating a submission
  id?: string;
  questionnaireId: string;
  status?: "completed";
  questions: Array<ICHSubmissionQuestion>;
}

export interface ICHSubmissionQuestion {
  // ID of the question your responses belongs to
  id: string;
  // Array of responses for the question. It archives all previous responses to the question.
  // Provide an empty array to archives all responses to the question.
  responses: Array<ICHSubmissionResponse>;
}

export interface ICHSubmissionResponse {
  // ID of the answer linked to the response
  answerId?: string;
  // If not linked to an answer, or if answer is 'Other', the content of the response
  content?: string;
}

export interface IQuizInfo {
  id: string;
  title: string;
  slug: string;
}

export interface IConditionalRule {
  condition: IConditionalRuleCondition;
  effect: IConditionalRuleEffect;
}

export interface IConditionalRuleCondition {
  questionTitle: string;
  comparison: string;
  answerTitle: string;
}

export interface IConditionalRuleEffect {
  goToPageNumber?: number;
  endQuiz?: boolean;
  hidePage?: boolean;
}
