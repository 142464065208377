import { IUserDetails } from "@/types";

// Determine if Kit is for the account holder or someone else
export enum EKitPerson {
  Me = "ME",
  Else = "ELSE",
}

export enum EKitUser {
  person = "person",
  kitNumber = "kitNumber",
}

export enum EAtkValues {
  Order = "Order",
  Results = "Results",
  Later = "Later",
}

export interface IKits {
  id: number;
  kitNumber: string;
  createdAt: string;
  personDetails: IUserDetails;
  resultsPdfUrl?: string;
  status: string;
  updatedAt: string;
  userId: number;
}

export enum EKitUiStatus {
  showTable = "showTable",
  showMessage = "showMessage",
  denyAccess = "denyAccess",
}

export enum EKitStatus {
  Received = "RECEIVED_RESULTS",
  Waiting = "WAITING",
}

export interface IResultKit {
  id?: number;
  name: string;
  kitNumber: string;
  registrationDate: string;
  resultsPdfUrl?: string;
  status?: string;
  hasResults?: boolean;
}
