import * as React from "react";
import {
  ResponsiveImage,
  CheckoutVariant,
  ICheckoutCouponDetails,
} from "@/types";

export enum ProductIntervals {
  thirtyDay = "30",
  ninetyDay = "90",
  oneOff = "0",
}

// why doesn't specifying allowed values work?
export interface PriceOption {
  id?: string;
  amount?: number;
  type?: string; // "recurring" | "one_time"
  recurring?: {
    interval: string;
    intervalCount: number; // 30 | 90 | 0
  };
}

export interface Product {
  id?: string;
  handle?: string;
  title?: string;
  name?: string; // TODO: Dato currently uses name for title, needs merge
  genericName?: string;
  description?: string;

  // Purchasing rules
  // [`carepointOnly` is DEPRECATED, TO REMOVE IN FAVOUR OF `!subscription`]: OTC and Public products (eg. ATK, Lozenges, Fexofenadine) are always fulfilled by Carepoint, irrelevant of user's pharmacy choice or product requiring insurance. They are mostly subscription based but can be one-offs, eg. ATK.
  carepointOnly?: boolean;
  // Rx and Rx + Insurance products always require a Doctor's Prescription.
  // They are always subscription based.
  prescriptionOnly?: boolean;
  // Does the product purchase require proof of insurance?
  // If so, we do not sell it.
  requiresInsurance?: boolean;
  // Is the product purchase recurring?
  subscription?: boolean;

  // pricing
  // !shop checkout: interval is order-wide
  selectedPriceInterval?: ProductIntervals;
  // shop checkout: interval is per product
  interval?: ProductIntervals;
  price?: number;
  priceOptions?: {
    30?: PriceOption;
    90?: PriceOption;
    oneOff?: PriceOption;
  };
  compareAtPrice?: string; // show price discounts
  quantity?: number;

  // assets
  tags?: string | any;
  image?: string;
  datoImage?: {
    responsiveImage: ResponsiveImage;
  };
}

// TODO: extend?
// export interface ProductUI extends Product {
export interface ProductUI {
  // accountUsingCarepoint allows setting discounts for a product
  accountUsingCarepoint?: boolean;
  // variants
  isRegistrationCheckout?: boolean;
  checkoutVariant?: CheckoutVariant;
  showCheckoutStyle?: boolean;
  // states
  isDisabled?: boolean;
  // layout
  order?: number;
  showCustomPharmacyRxPrice?: boolean;
  matchImageLast?: boolean;
  showImageLarge?: boolean;
  showImageLast?: boolean;
  showRule?: boolean;
  // slots
  input?: React.ReactNode;
  altPrice?: React.ReactNode;
  priceSlot?: React.ReactNode;
  priceSlotSubscription?: React.ReactNode;
  priceSlotSingle?: React.ReactNode;
  priceSlotInsurance?: React.ReactNode;
  priceSlotCustomPharmacy?: React.ReactNode;
  couponNote?: string | React.ReactNode;
  changeToRecurringSlot?: React.ReactNode;
  mobileImageSlot?: React.ReactNode;
  collapseSlot?: React.ReactNode;
  // props
  wrapperProps?: Record<string, any>;
  couponDetails?: ICheckoutCouponDetails;
  discountedPrice?: number;
}

export interface DatoProduct {
  id?: string;
  name: string;
  handle: string;
  image?: {
    responsiveImage: ResponsiveImage;
  };
  faq?: {
    id: string;
    question: string;
    answer: string;
    group: string;
  };
}

// Merge Type declarations
// See: https://www.notion.so/callum/Typescript-or-JSdocs-d4af578f95c3463c9af3664b3a33be29#868a07b5f0cb45aeaf726fef6f0eabbc
export class ProductTypes {}
export interface ProductTypes extends Product, ProductUI {}
