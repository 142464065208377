/**
 * Ensures all numbers have +1 at the beginning
 *
 * This method is used to make the react-phone-number-input less brittle
 * with phone numbers that don't have a US country code.
 *
 * For example
 * - "234-567-8901" => "+1234-567-8901"
 * - "1-234-567-8901" => "+1-234-567-8901"
 */

export const getPhoneNumberWithUSCountryCode = (number: string): string => {
  let numberWithCountryCode;
  if (number) {
    if (number.startsWith("+1")) {
      numberWithCountryCode = number;
    } else if (number.startsWith("1")) {
      numberWithCountryCode = `+${number}`;
    } else {
      numberWithCountryCode = `+1${number}`;
    }
  }
  return numberWithCountryCode;
};
