import React from "react";
import PropTypes from "prop-types";
import {
  Input,
  FormControl,
  FormLabel,
  FormHelperText,
  Box,
  FormErrorMessage,
} from "@chakra-ui/react";
import useActiveOnFocus from "@/hooks/useActiveOnFocus";
import PhoneInput from "react-phone-number-input/input";
import { getPhoneNumberWithUSCountryCode } from "./utils";

/*

  Floating label input
  Styled like: https://material.io/components/text-fields

 */

// INPUT: spread props so we can set type eg. "date"
// phoneNumber inputs need a special PhoneInput for number formatting. Note
// that onChange is a value not an event for PhoneInput.
const InputSwitch = React.forwardRef(
  ({ active, eventHandlers, value, helpers, fieldProps, ...props }, ref) => {
    if (props.kind === "phoneNumber") {
      // Formats input, doesn't validate
      return (
        <Input
          as={PhoneInput}
          country={"US"}
          size={props.size}
          variant={props.variant}
          pt={active || value ? "11px" : "0"}
          {...fieldProps}
          onChange={helpers.setValue}
          value={getPhoneNumberWithUSCountryCode(value)}
          onFocus={eventHandlers.onFocus}
          onBlur={(...args) => {
            eventHandlers.onBlur(...args);
            fieldProps?.onBlur(...args);
          }}
          {...props}
          id={props.id || props.name}
        />
      );
    }

    if (props.kind === "addressAutoComplete") {
      return (
        <Input
          ref={ref}
          size={props.size}
          variant={props.variant}
          pt={active || value ? "11px" : "0"}
          {...fieldProps}
          value={value}
          onFocus={eventHandlers.onFocus}
          onBlur={(...args) => {
            eventHandlers.onBlur(...args);
            fieldProps?.onBlur(...args);
          }}
          {...props}
          id={props.id || props.name}
        />
      );
    }

    return (
      // id prop is the last prop passed because if props.id === "", we want the id to be props.name
      <Input
        size={props.size}
        variant={props.variant}
        pt={active || value ? "11px" : "0"}
        {...fieldProps}
        value={value}
        onFocus={eventHandlers.onFocus}
        onBlur={(...args) => {
          eventHandlers.onBlur(...args);
          fieldProps?.onBlur(...args);
        }}
        {...props}
        id={props.id || props.name}
      />
    );
  }
);
InputSwitch.displayName = "InputSwitch";

InputSwitch.propTypes = {
  kind: PropTypes.string,
  id: PropTypes.string,
  name: PropTypes.string,
  size: PropTypes.string,
  variant: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  active: PropTypes.bool,
  helpers: PropTypes.objectOf(PropTypes.any),
  fieldProps: PropTypes.objectOf(PropTypes.any),
  eventHandlers: PropTypes.objectOf(PropTypes.any),
};

/* eslint-disable react/display-name */
const InputBase = React.forwardRef(
  ({ error, value, helpers, fieldProps, controlspace, ...props }, ref) => {
    const [active, eventHandlers] = useActiveOnFocus();

    // console.log("InputBase error", error);

    return (
      <FormControl
        isInvalid={!!error}
        id={props.name || props.label}
        isRequired={props.required}
        mb={controlspace && "w2"}
      >
        <Box pos="relative">
          <FormLabel
            htmlFor={props.id || props.name}
            variant="floatingLabel"
            fontWeight={active || value ? "medium" : "normal"}
            color={active || value ? "black" : "blackAlpha.600"}
            opacity={props.kind === "date" && !value && 0}
            transform={
              active || value
                ? "scale(0.6) translate(1.7em, 12px)"
                : "scale(1) translate(0.941176471em, 0.8em)"
            }
          >
            {props.label}
          </FormLabel>
          <InputSwitch
            ref={ref}
            active={active}
            eventHandlers={eventHandlers}
            value={value}
            helpers={helpers}
            fieldProps={fieldProps}
            {...props}
          />
        </Box>

        {props.helptext && (
          <FormHelperText
            color="blackAlpha.600"
            w={{ base: "full", xl: 5 / 6 }}
          >
            {props.helptext}
          </FormHelperText>
        )}
        {/* This component must be wrapped in formik context,
        else `error` does nothing for regular inputs */}
        {error && <FormErrorMessage color="form.bad">{error}</FormErrorMessage>}
      </FormControl>
    );
  }
);
/* eslint-enable react/display-name */

InputBase.propTypes = {
  // Value is for non formik inputs
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  // Below are for formik helpers
  helpers: PropTypes.objectOf(PropTypes.any),
  // Below are for formik inputs
  fieldProps: PropTypes.objectOf(PropTypes.any),
  // The base style props
  error: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  id: PropTypes.string,
  name: PropTypes.string,
  label: PropTypes.string.isRequired,
  helptext: PropTypes.string,
  size: PropTypes.string,
  variant: PropTypes.string,
  controlspace: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.array,
    PropTypes.string,
  ]),
  required: PropTypes.bool,
  kind: PropTypes.oneOf([
    "date",
    "phoneNumber",
    "addressAutoComplete",
    undefined,
  ]),
};

InputBase.defaultProps = {
  value: "",
  fieldProps: null,
  error: null,
  id: "",
  name: null,
  size: "rg",
  variant: "default",
  helptext: null,
  controlspace: false,
  required: false,
  kind: null,
};

export default InputBase;
