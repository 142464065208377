/**
 * Shorthand to create an enum object
 * Named enu because enum is a reserved keyword in JS
 *
 * @example
 * // returns {
 * //   PRIMARY: "PRIMARY",
 * //   SECONDARY: "SECONDARY",
 * // }
 * enu(["PRIMARY", "SECONDARY"]);
 */
export const enu = (arr) => {
  const object = arr.reduce(
    (acc, item) => ({
      ...acc,
      [item]: item,
    }),
    {}
  );
  return Object.freeze(object);
};

/**
 * Utils
 */

// Show to user in friendly format
// Send to server in constants format
const capitalize = (str) =>
  str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
export const constantToFriendly = (value) => capitalize(value);
export const friendlyToConstant = (value) => value.toUpperCase();

/**
 * Constants
 */

// User
export const GENDERS = enu(["MALE", "FEMALE"]);
export const GENDER_PRONOUNS = enu([
  "He/His",
  "She/Hers",
  "They/Them",
  "Ze/Zir",
  "Other",
]);
// Profile status comes from the backend, these are fixed values
export const PROFILE_STATUS = enu([
  "QUESTIONNAIRE",
  "REQUEST_RECOMMENDATION",
  "AWAITING_ENCOUNTER_APPROVAL",
]);

// Allergy questionaire
export const ALLERGY_QUESTIONAIRE_SECTIONS = {
  ALLERGIES: "About your allergies",
  HISTORY: "History + medications",
  IMMUNOTHERAPY: "Allergy immunotherapy",
  GENERAL: "General health",
  PRESCRIPTION: "Arranging prescription",
};

// US States
// https://gist.github.com/iamjason/8f8f4bc00c13de86bcad
export const ALL_STATES = enu([
  "Alaska",
  "Alabama",
  "Arkansas",
  "Arizona",
  "California",
  "Colorado",
  "Connecticut",
  "District of Columbia",
  "Delaware",
  "Florida",
  "Georgia",
  "Hawaii",
  "Iowa",
  "Idaho",
  "Illinois",
  "Indiana",
  "Kansas",
  "Kentucky",
  "Louisiana",
  "Massachusetts",
  "Maryland",
  "Maine",
  "Michigan",
  "Minnesota",
  "Missouri",
  "Mississippi",
  "Montana",
  "North Carolina",
  "North Dakota",
  "Nebraska",
  "Nevada",
  "New Hampshire",
  "New Jersey",
  "New Mexico",
  "New York",
  "Ohio",
  "Oklahoma",
  "Oregon",
  "Pennsylvania",
  "Rhode Island",
  "South Carolina",
  "South Dakota",
  "Tennessee",
  "Texas",
  "Utah",
  "Virginia",
  "Vermont",
  "Washington",
  "Wisconsin",
  "West Virginia",
  "Wyoming",
]);

// States supported by cleared
export const SUPPORTED_STATES = enu([
  ALL_STATES.Alabama,
  ALL_STATES.Alaska,
  ALL_STATES.Arizona,
  ALL_STATES.Arkansas,
  ALL_STATES.California,
  ALL_STATES.Colorado,
  ALL_STATES.Connecticut,
  ALL_STATES.Delaware,
  ALL_STATES["District of Columbia"],
  ALL_STATES.Florida,
  ALL_STATES.Georgia,
  ALL_STATES.Hawaii,
  ALL_STATES.Idaho,
  ALL_STATES.Illinois,
  ALL_STATES.Indiana,
  ALL_STATES.Iowa,
  ALL_STATES.Kansas,
  ALL_STATES.Kentucky,
  ALL_STATES.Louisiana,
  ALL_STATES.Maine,
  ALL_STATES.Maryland,
  ALL_STATES.Massachusetts,
  ALL_STATES.Michigan,
  ALL_STATES.Minnesota,
  ALL_STATES.Mississippi,
  ALL_STATES.Missouri,
  ALL_STATES.Montana,
  ALL_STATES.Nebraska,
  ALL_STATES.Nevada,
  ALL_STATES["New Hampshire"],
  ALL_STATES["New Jersey"],
  ALL_STATES["New Mexico"],
  ALL_STATES["New York"],
  ALL_STATES["North Carolina"],
  ALL_STATES["North Dakota"],
  ALL_STATES.Ohio,
  ALL_STATES.Oklahoma,
  ALL_STATES.Oregon,
  ALL_STATES.Pennsylvania,
  ALL_STATES["Rhode Island"],
  ALL_STATES["South Carolina"],
  ALL_STATES["South Dakota"],
  ALL_STATES.Tennessee,
  ALL_STATES.Texas,
  ALL_STATES.Utah,
  ALL_STATES.Vermont,
  ALL_STATES.Virginia,
  ALL_STATES.Washington,
  ALL_STATES["West Virginia"],
  ALL_STATES.Wisconsin,
  ALL_STATES.Wyoming,
]);

export const ATK_NOT_SUPPORTED_STATES = enu([ALL_STATES["New York"]]);

// Register allergy test kit
export const PERSON = enu(["ME", "ELSE"]);
