import React from "react";
import PropTypes from "prop-types";
import { useField } from "formik";
import InputBase from "@/components/Form/InputBase";

// Reusable Formik Chakra Input
// https://formik.org/docs/tutorial#leveraging-react-context
// TODO: Update RefType and PropType from any to the right type
const DefaultInput = React.forwardRef<any, any>(
  ({ validate, ...props }, ref) => {
    const [field, meta, helpers] = useField({
      validate,
      ...props,
    });
    // console.log("DefaultInput formik", field, meta, helpers);
    // console.log("DefaultInput props", props);

    // Allows debounced validation
    // Allow a fields to handle it's only value by passing the debounced value
    // so validation is only run on debounce but the inputs value updates
    const value = props.value || field.value;

    return (
      <InputBase
        fieldProps={field}
        helpers={helpers}
        value={value}
        error={meta.touched && meta.error}
        ref={ref ? ref : undefined}
        {...props}
      />
    );
  }
);

DefaultInput.propTypes = {
  // Even undefined or null will be set as the value with useField,
  // only tracks it's own value if the property isn't passed
  // eslint-disable-next-line react/require-default-props
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  validate: PropTypes.func,
};
DefaultInput.defaultProps = {
  validate: undefined,
};

export default DefaultInput;
